import React, { HTMLAttributes, PropsWithChildren } from 'react'
import './list-text-icons.scss'
import clsx from 'clsx'

type ListTextIconsProps = PropsWithChildren<{
  elements: {
    description?: string
    title: string
    icon?: string
  }[]
  columns?: number
  align?: 'center' | 'left'
  numbers?: boolean
} & HTMLAttributes<HTMLDivElement>>

const ListTextIcons = ({ title, elements, numbers, columns, align = 'left', children, ...props }: ListTextIconsProps) => {

  const Tag = numbers ? 'ol' : 'ul'

  return (
    <div data-aos='fade' className={clsx('list-text-icons', `list-text-icons--${columns}`, `list-text-icons--align-${align}`, {
      'list-text-icons--numbers': numbers
    })} {...props}>
      {title && <h2 data-aos='fade-in' data-aos-offset='10'>{title}</h2>}
      <Tag>
        {elements.map(({ description, title, icon }, key) => (
          <li key={title}>
            {numbers && <div data-aos='fade-in' data-aos-offset='10' className='list-text-icons__number'>{key + 1}</div>}
            {icon && <div className='list-text-icons__icon'>
              <div className='list-text-icons__icon__content'>
                <img src={`${icon}`} />
              </div>
            </div>}
            <h4>{title}</h4>
            {description && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
          </li>
        ))}
      </Tag>

      {children ? <div className='list-text-icons__append'>
        {children}
      </div> : <></>}
    </div>
  )
}

export default ListTextIcons
