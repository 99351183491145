import React, { PropsWithChildren } from "react"
import type { HTMLAttributes } from "react";
import clsx from "clsx"
import * as style from "./section.module.scss"

type SectionProps = PropsWithChildren<{
  className?: string
  anchor?: string
}> & HTMLAttributes<HTMLDivElement>

const Section = ({ children, className, anchor, ...props }: SectionProps) => {
  return (
    <section id={anchor} className={clsx(style.section, className)} {...props}>
      {children}
    </section>
  )
}

export default Section
