import React, { Component, PropsWithChildren } from 'react'
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary'
import Container from '../Container/Container'
import './text-row.scss'
import clsx from 'clsx'

type TextRowProps = PropsWithChildren<{
  title?: string
  body?: string
  button?: string
  top?: null | any
  tag?: 'p' | string
  bg?: 'neutral-0' | 'neutral-50'
}>

class TextRow extends Component<TextRowProps> {

  constructor(props: TextRowProps) {
    super(Object.assign({ bg: 'neutral-0' }, props))
  }

  render () {
    const tag = this.props.tag || 'p'
    return (
      <section className={clsx('text-row', `text-row--${this.props.bg}`)}>
        <Container>
          {this.props.top && <div className='text-row__top'>{this.props.top}</div>}
          {this.props.title && <h2 data-aos='fade-in' data-aos-delay='300' data-aos-offset='10'>{this.props.title}</h2>}
          {tag === 'p' && <p data-aos='fade-in' data-aos-delay='300' data-aos-offset='10' dangerouslySetInnerHTML={{__html: this.props.body }}></p>}
          {tag !== 'p' && <div data-aos='fade-in' data-aos-delay='300' data-aos-offset='10' dangerouslySetInnerHTML={{__html: this.props.body }}></div>}
          {this.props.button && <ButtonPrimary href="/location" data-aos='fade-in' data-aos-delay='300' data-aos-offset='10'>{this.props.button}</ButtonPrimary>}
          {this.props.children}
        </Container>
      </section>
    )
  }
}

export default TextRow
