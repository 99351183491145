import React from "react";
import Container from "../../Container/Container";
import Section from "../../Section/Section";
import image from '../../../images/carbon-free/certificazione-gadames.jpg';
import * as style from './carbon-free-certification-section.module.scss';

export default function CarbonFreeCertificationSection() {
  return (
    <Section className={style.section}>
      <Container className={style.container}>
        <div>
          <h2>Una location ad EMISSIONI 0</h2>
          <p>Le location Kready possono ottenere la certificazione Carbon Free <b>su richiesta per ogni evento</b>.</p>
          <p>
            Questo servizio si applica esclusivamente alle <b>emissioni generate dalla location</b> e dal personale che vi opera, non dall'intero evento. Ad esempio, le emissioni derivanti dagli spostamenti degli invitati non sono incluse nel calcolo.</p>
          <p>
            Questo significa che durante il periodo del tuo evento, verranno adottati sistemi per <b>ridurre le emissioni di CO<sub>2</sub></b> generate e tali emissioni verranno compensate <b>sostenendo progetti certificati internazionalmente</b> (Verified Carbon Standard e Gold Standard) che hanno come obiettivo la <b>cattura</b>, la <b>riduzione</b> o la non emissione di CO<sub>2</sub>.</p>
        </div>
        <div>
          <div className={style.picture}>
            <img src={image} className={style.image} alt="Vista di Gadames dal prato" />
          </div>
        </div>
      </Container>
    </Section>
  )
}
