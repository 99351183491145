import React, { PropsWithChildren } from 'react';

import * as style from './motto.module.scss';

const Motto = ({ body, children }: PropsWithChildren<{ body?: string }>) => {
  return (
    <div className={style.motto}>
      <div className={style.content}>
        {body && <p dangerouslySetInnerHTML={{ __html: body ?? '' }}></p>}
        {children && <p>{children}</p>}
      </div>
    </div>
  )
}

export default Motto
