import React, { Component } from 'react'
import ParallaxMediaProps from './ParallaxMediaProps'

import './parallax-media.scss'

class ParallaxMedia extends Component {

  private video = ''
  private image = ''
  private title = ''

  constructor(props: ParallaxMediaProps) {
    super(props)
    const { video, image, title } = props
    this.video = video || ''
    this.image = image || ''
    this.title = title || ''
  }

  render () {
    const style = {
      'backgroundImage': `url(${this.image})`
    }

    return (
      <div className={`parallax-media ${this.props.fixed ? 'parallax-media--fixed' : ''}`} style={style}>
        {this.video && (
          <video src={this.video} muted loop autoPlay></video>
        )}
        {this.title && <div data-aos='fade-in' className='parallax-media__title'><span>{this.title}</span></div>}
      </div>
    )
  }
}

export default ParallaxMedia
