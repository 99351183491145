import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm/ContactForm'
import MediaBox from '../components/MediaBox/MediaBox'
import TextRow from '../components/TextRow/TextRow'
import ParallaxMedia from '../components/ParallaxMedia/ParallaxMedia'
import ListTextIcons from '../components/ListTextIcons/list-text-icons'

// icons
import iconInnovation from '../images/carbon-free/innovazione-competitivita.svg'
import iconEnvironment from '../images/carbon-free/impatto-positivo-ambiente.svg'
import iconReputation from '../images/carbon-free/reputation.svg'

import image from '../images/carbon-free/gadames-palace.jpg'
import mediaBoxImage from '../images/carbon-free/trees.jpg'
import Motto from '../components/Motto/Motto'
import CarbonFreeCertificationSection from '../components/CarbonFree/CarbonFreeCertificationSection/CarbonFreeCertificationSection'

// markup
const AboutUs = () => {
  return (
    <Layout className='carbon-free'>
      <Helmet>
        <title>Location certificabili per eventi Carbon Free | Kready</title>
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
          rel='stylesheet'
          type='text/css'
        />
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
          rel='stylesheet'
          type='text/css'
        />
      </Helmet>
      <MediaBox
        image={mediaBoxImage}
        title='Gadames 57 è pronta per contribuire a rendere il tuo evento Carbon Free'
        layer='desktop'
        darker
      ></MediaBox>
      <Motto>
        Migliora l'impatto del tuo evento rendendo la <b>location Carbon Free</b>.
        <br /><br />
        Grazie al nostro servizio di certificazione, i tuoi eventi aziendali, meeting e congressi saranno sempre più <b>sostenibili</b> e <b>rispettosi dell'ambiente</b>.
      </Motto>
      <TextRow bg="neutral-50" title="Cos’è la certificazione Carbon Free per le location Kready?" body="Attraverso Kready, puoi richiedere che la <b>location utilizzata</b> venga <b>certificata Carbon Free</b>, garantendo che ogni emissione provocata dagli spazi affittati venga gestita con un'attenzione particolare all'ambiente.">
      </TextRow>
      <CarbonFreeCertificationSection />
      <ListTextIcons numbers columns={2} title='Come funziona la certificazione Carbon Free?' elements={[
        { title: 'Calcolo delle emissioni', description: 'Il nostro partner utilizza strumenti avanzati per calcolare <b>la carbon footprint</b> degli spazi scelti per il tuo evento. Questo include esclusivamente <b>tutte le emissioni generate dalla location dell’evento</b>, dall’organizzazione agli spostamenti del nostro personale, ai consumi energetici e a tutti gli altri elementi inerenti alla location.' },
        { title: 'Riduzione delle emissioni', description: 'Il nostro partner fornisce <b>consigli personalizzati per ridurre l\'impatto ambientale</b>, come l\'adozione di pratiche sostenibili e l\'ottimizzazione delle risorse utilizzate all\'interno della location.' },
        { title: 'Compensazione delle emissioni', description: 'Le emissioni residue si compensano attraverso l\'acquisto di <b>crediti di carbonio certificati</b>, sostenendo progetti internazionali che catturano o evitano l\'emissione di CO₂.' },
        { title: 'Certificazione', description: 'Una volta completati questi passaggi, la tua location sarà certificata Carbon Free, attestando il tuo impegno verso un futuro più sostenibile.' },
      ]}></ListTextIcons>
      <ListTextIcons align='center' title='Perché rendere il tuo evento Carbon Free?' elements={[
        { title: 'IMPATTO POSITIVO SULL\'AMBIENTE', icon: iconInnovation },
        { title: 'MIGLIORA LA BRAND REPUTATION', icon: iconEnvironment },
        { title: 'INNOVAZIONE E COMPETITIVITÀ', icon: iconReputation },
      ]}></ListTextIcons>
      <ParallaxMedia image={image}></ParallaxMedia>
      <ContactForm title="Certifica ora la tua location" description="Ottieni tutte le informazioni necessarie per richiedere la certificazione Carbon Free per il tuo evento."></ContactForm>
    </Layout >
  )
}

export default AboutUs
